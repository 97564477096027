.vaillant_button_style {
    width: 50% !important;
    background: #00897D !important;
    color: white !important;
}
.vaillant_button_style:hover{
    background: white !important;
    color: #00897D !important;
    border-color: #00897D !important;
}

.sd_button_style {
    width: 50% !important;
    background: #D40C2D !important;
    color: white !important;
}

.sd_button_style:hover{
    background: white !important;
    color: #D40C2D !important;
    border-color: #D40C2D !important;
}

.login_as_employee:hover{
    color: #00237a;
}