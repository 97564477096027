.ant-collapse-borderless > .ant-collapse-item {
    border-bottom: none;
}
.brand-button {
    cursor: pointer;
}

.ant-menu-item-selected,
.ant-menu-item-selected::after,
.ant-menu-inline .ant-menu-item::after,
.ant-menu-item-selected > a
{
  background-color: transparent !important;
  border-right: none !important;
  color: inherit !important;
}

.template-card > .ant-card-body {
  padding: 10px 15px;
}

.equipment-carousel .ant-card {
  margin: 5px;
}

.ant-input {
    font-size: 16px;
}

.ant-layout.ant-layout-has-sider > .ant-layout, .ant-layout.ant-layout-has-sider > .ant-layout-content {
    overflow-y: hidden;
}