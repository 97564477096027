body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.comparsionPreviewTableMobile{
}

.comparsionPreviewTableMobile thead td{
  min-width: 80vw;
  border: 2px solid #ededed;
}

.comparsionPreviewTableMobile div{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.comparsionPreviewTableMobile .parameter_name{
  font-weight: bold;
}

.comparsionPreviewTableMobile .V{
  background-color: #ecfbf4;
}

.comparsionPreviewTableMobile .S{
  background-color: #fdedf1;
}
.comparsionPreviewTableMobile td{
  text-align: center;
  border-right: 2px solid #ededed;
}

.comparsionPreviewTableDesktop{
  min-width: 100%;
}

.comparsionPreviewTableDesktop div{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.comparsionPreviewTableDesktop td{
  border: 2px solid #ededed;
}

.comparsionPreviewTableDesktop.V tbody tr:nth-child(odd){
  background-color: #ecfbf4;
}

.comparsionPreviewTableDesktop.V tbody tr:hover{
  background-color: #00bda1;
}

.comparsionPreviewTableDesktop.S tbody tr:nth-child(odd){
  background-color: #fdedf1;
}

.comparsionPreviewTableDesktop.S tbody tr:hover{
  background-color: #c52b45;
}

.comparsionPreviewTableDesktop .parameter_name{
  font-weight: bold;
}